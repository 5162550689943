import Axios from 'axios';

export default {
  /**
   * 商业计划-分页查询
   */
  async businessPlanQueryBusinessPlanPage(query = {}) {
    return Axios.post('/businessPlan/queryBusinessPlanPage', query);
  },
  /**
   * 商业计划-列表查询
   */
  async businessPlanQueryBusinessPlanList(query = {}) {
    return Axios.post('/businessPlan/queryBusinessPlanList', query);
  },
  /**
   * 商业计划-详情
   */
  async businessPlanGetBusinessPlan(query = {}) {
    return Axios.post('/businessPlan/getBusinessPlan', query);
  },
  /**
   * 商业计划-新增
   */
  async businessPlanAddBusinessPlan(query = {}) {
    return Axios.post('/businessPlan/addBusinessPlan', query);
  },
  /**
   * 公司-分页查询
   */
  async companyQueryCompanyPage(query = {}) {
    return Axios.post('/company/queryCompanyPage', query);
  },
  /**
   * 公司-列表查询
   */
  async companyQueryCompanyList(query = {}) {
    return Axios.post('/company/queryCompanyList', query);
  },
  /**
   * 公司-详情
   */
  async companyGetCompany(query = {}) {
    return Axios.post('/company/getCompany', query);
  },
  /**
   * 资讯-分页查询
   */
  async informationQueryInformationPage(query = {}) {
    return Axios.post('/information/queryInformationPage', query);
  },
  /**
   * 资讯-列表查询
   */
  async informationQueryInformationList(query = {}) {
    return Axios.post('/information/queryInformationList', query);
  },
  /**
   * 资讯-详情
   */
  async informationGetInformation(query = {}) {
    return Axios.post('/information/getInformation', query);
  },
  /**
   * 展示链接-分页查询
   */
  async linkQueryLinkPage(query = {}) {
    return Axios.post('/link/queryLinkPage', query);
  },
  /**
   * 展示链接-列表查询
   */
  async linkQueryLinkList(query = {}) {
    return Axios.post('/link/queryLinkList', query);
  },
  /**
   * 展示链接-详情
   */
  async linkGetLink(query = {}) {
    return Axios.post('/link/getLink', query);
  },
  /**
   * 招聘-分页查询
   */
  async recruitQueryRecruitPage(query = {}) {
    return Axios.post('/recruit/queryRecruitPage', query);
  },
  /**
   * 招聘-列表查询
   */
  async recruitQueryRecruitList(query = {}) {
    return Axios.post('/recruit/queryRecruitList', query);
  },
  /**
   * 招聘-详情
   */
  async recruitGetRecruit(query = {}) {
    return Axios.post('/recruit/getRecruit', query);
  },
  /**
   * 地区-级联列表查询
   */
  async regionQueryRegionListCascade(query = {}) {
    return Axios.post('/region/queryRegionListCascade', query);
  },
  /**
   * 地区-省会列表查询
   */
  async regionQueryProvincialCapitalList(query = {}) {
    return Axios.post('/region/queryProvincialCapitalList', query);
  },
  /**
   * 轮播图-分页查询
   */
  async slideshowQuerySlideshowPage(query = {}) {
    return Axios.post('/slideshow/querySlideshowPage', query);
  },
  /**
   * 轮播图-列表查询
   */
  async slideshowQuerySlideshowList(query = {}) {
    return Axios.post('/slideshow/querySlideshowList', query);
  },
  /**
   * 轮播图-详情
   */
  async slideshowGetSlideshow(query = {}) {
    return Axios.post('/slideshow/getSlideshow', query);
  },
  /**
   * 创建团队-分页查询
   */
  async teamQueryTeamPage(query = {}) {
    return Axios.post('/team/queryTeamPage', query);
  },
  /**
   * 创建团队-列表查询
   */
  async teamQueryTeamList(query = {}) {
    return Axios.post('/team/queryTeamList', query);
  },
  /**
   * 创建团队-详情
   */
  async teamGetTeam(query = {}) {
    return Axios.post('/team/getTeam', query);
  },
  /**
   * 文件-OSS图片文件上传（base64）
   */
  async fileUploadBase64(query = {}) {
    return Axios.post('/file/uploadBase64', query);
  },
  /**
   * 文件-OSS图片文件上传（MultipartFile）
   */
  async fileUpload(query = {}) {
    return Axios.post('/file/upload', query);
  },
};
