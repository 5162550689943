import Vue from 'vue';
import Axios from 'axios';
import { Message } from 'element-ui';
// import { cancelAllReq } from '@/utils';

Axios.defaults.baseURL = '/advc-portal-api';
Axios.defaults.timeout = 300000;

window.__axiosPromiseArr = [];

// Axios 请求拦截器
Axios.interceptors.request.use((config) => {
  config.headers['Content-Type'] = 'application/json; charset=utf-8';
  config.headers['ROUTE_CHANNEL'] = '/portal-api';
  // 将cancel放置于全局变量的数组中, 用于取消请求
  config.cancelToken = new Axios.CancelToken((cancel) => {
    window.__axiosPromiseArr.push({
      cancel,
    });
  });
  return config;
});

// Axios 响应拦截器 对返回码进行处理处理
Axios.interceptors.response.use(
  (res) => {
    const {
      status,
      data: { rspCd, rspInf, data },
    } = res;

    if (status === 200) {
      if (rspCd === '00000') return data;
      else if (rspCd === '30002') {
        // 取消axios的请求
        // cancelAllReq();
      }
    }
    if (rspInf) {
      Message.error(rspInf);
    }
    return Promise.reject(rspInf);
  },
  (err) => {
    if (err.response) {
      switch (err.response.status) {
        case 502:
          Message.error('服务器内部错误');
          break;
        case 401:
          Message.error('会话已失效, 请重新登录');
          // 取消axios的请求
          window.__axiosPromiseArr.forEach((ele, idx) => {
            ele.cancel();
            delete window.__axiosPromiseArr[idx];
          });
          Vue.prototype.$loginOut(false);
          break;
        case 404:
          Message.error('请求地址错误');
          break;
        case 405:
          Message.error('请求方式错误');
          break;
        default:
          Message.error('服务器繁忙');
          break;
      }
    }
    return Promise.reject(err.response);
  }
);
