import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import $ from 'jquery';
import './plugins';
import './styles/index.css';
import echarts from 'echarts';
import './api/config';
import api from './api';

Vue.prototype.$api = api; //引入组件
Vue.prototype.$echarts = echarts; //引入组件

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
