<template>
  <div id="app" :class="{ mobile: isMobile }">
    <router-view />
  </div>
</template>

<script>
import { isMobile } from 'is-mobile';
export default {
  name: 'App',
  data() {
    return {
      isMobile: isMobile(),
    };
  },
  provide() {
    return {
      isMobile: isMobile(),
    };
  },
};
</script>

<style lang="scss">
#app {
  font-family: harmony, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  &.mobile {
    // overflow-x: hidden;
  }
}
</style>
