import Vue from 'vue';
import 'element-ui/lib/theme-chalk/index.css';
import {
  Aside,
  Autocomplete,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Header,
  Main,
  Menu,
  MenuItem,
  MenuItemGroup,
  Submenu,
  Tabs,
  TabPane,
  Table,
  TableColumn,
  Tree,
  Tooltip,
  Pagination,
  Popover,
  Loading,
  Input,
  InputNumber,
  Select,
  Option,
  Col,
  Row,
  Drawer,
  Form,
  FormItem,
  Radio,
  RadioGroup,
  RadioButton,
  DatePicker,
  Notification,
  Checkbox,
  CheckboxGroup,
  Cascader,
  Message,
  Scrollbar,
  Carousel,
  CarouselItem,
  Progress,
  Card,
  MessageBox,
  Tag,
  Upload,
  Image,
  Dialog,
  Switch,
  Avatar,
  Collapse,
  CollapseItem,
  Divider,
} from 'element-ui';

Vue.use(Button)
  .use(Col)
  .use(Row)
  .use(Menu)
  .use(Submenu)
  .use(MenuItem)
  .use(MenuItemGroup)
  .use(Container)
  .use(Header)
  .use(Aside)
  .use(Main)
  .use(Tabs)
  .use(TabPane)
  .use(Table)
  .use(TableColumn)
  .use(Pagination)
  .use(Popover)
  .use(Loading)
  .use(Input)
  .use(InputNumber)
  .use(Select)
  .use(Option)
  .use(Drawer)
  .use(Form)
  .use(FormItem)
  .use(Radio)
  .use(RadioGroup)
  .use(RadioButton)
  .use(DatePicker)
  .use(Checkbox)
  .use(CheckboxGroup)
  .use(Cascader)
  .use(Scrollbar)
  .use(Carousel)
  .use(CarouselItem)
  .use(Progress)
  .use(Card)
  .use(Tag)
  .use(Breadcrumb)
  .use(BreadcrumbItem)
  .use(Upload)
  .use(Image)
  .use(Dropdown)
  .use(DropdownMenu)
  .use(DropdownItem)
  .use(Tree)
  .use(Dialog)
  .use(Switch)
  .use(Tooltip)
  .use(Autocomplete)
  .use(Avatar)
  .use(Collapse)
  .use(CollapseItem)
  .use(Divider);

Vue.prototype.$notify = Notification;
Vue.prototype.$message = Message;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$loading = Loading.service;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;

// 确认删除
Vue.prototype.$confirmWithApi = (message, actionText = '删除', api) => {
  return new Promise((resolve, reject) => {
    MessageBox.confirm(message, '温馨提示', {
      type: 'warning',
      confirmButtonText: actionText,
      closeOnClickModal: false,
      async beforeClose(action, instance, done) {
        if (action === 'cancel' || action === 'close') return done();
        instance.confirmButtonText = `${actionText}中...`;
        instance.confirmButtonLoading = true;
        try {
          await api();
          Notification.success({
            title: '成功',
            message: `${actionText}成功`,
          });
          done();
          resolve();
        } catch (e) {
          reject(e);
        }
        instance.confirmButtonText = actionText;
        instance.confirmButtonLoading = false;
      },
    });
  });
};

Vue.prototype.$viewImage = (url) => {
  if (!url) {
    return Notification.error({
      title: '错误',
      message: '图片加载失败',
    });
  }
  const h = Vue.prototype.$h;
  MessageBox({
    title: '',
    showCancelButton: false,
    showConfirmButton: false,
    lockScroll: false,
    showClose: false,
    customClass: 'img-msg-box',
    message: h('img', {
      attrs: {
        src: url,
        class: 'img-msg-box__img',
      },
    }),
  }).catch((e) => {
    return e;
  });
};
