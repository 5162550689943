import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: () => import('@/layouts/Main.vue'),
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import('@/views/Home.vue'),
      },
    ],
  },
  {
    path: '/about',
    component: () => import('@/layouts/Main.vue'),
    children: [
      {
        path: '',
        name: 'About',
        component: () => import('@/views/About.vue'),
      },
    ],
  },
  {
    path: '/article',
    component: () => import('@/layouts/Main.vue'),
    children: [
      {
        path: ':id',
        name: 'Article',
        component: () => import('@/views/Article.vue'),
      },
    ],
  },
  {
    path: '/branch',
    component: () => import('@/layouts/Main.vue'),
    children: [
      {
        path: '',
        name: 'Branch',
        component: () => import('@/views/Branch.vue'),
      },
    ],
  },
  {
    path: '/recruit',
    component: () => import('@/layouts/Main.vue'),
    children: [
      {
        path: '',
        name: 'Recruit',
        component: () => import('@/views/Recruit.vue'),
      },
    ],
  },
  {
    path: '/bp',
    component: () => import('@/layouts/Main.vue'),
    children: [
      {
        path: '',
        name: 'BP',
        component: () => import('@/views/BP.vue'),
      },
    ],
  },
];

const isDev = process.env.NODE_ENV === 'development';
const isProdHost = window.location.host.includes('www.ad-vc.net');

const router = new VueRouter({
  mode: 'history',
  base: isDev || isProdHost ? '/' : '/advc-portal',
  routes,
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  next();
});
export default router;
